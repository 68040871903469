import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Matic from "../assets/svg/matic.png";
import { Tab } from "@mui/material";

function PatientBalance() {
  const [data, setData] = useState([]);
  const [balance, setBalance] = useState();

  useEffect(() => {
    (async () => {
      let r = await axios({
        method: "get",
        url: `https://analytics-api.healthtag.io/pool-patient-balance`,
        withCredentials: false,
      });
      if (r.status === 200) {
        setData(r.data);
      }
    })();
  }, []);

  return (
    <div className="w-full h-auto items-center grid grid-cols-1 xl:flex justify-between flex-wrap mt-8">
      <p className="text-3xl font-bold">Patients Balance</p>
      <TableContainer component={Paper} className="mt-4 h-[600px]">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>PromptCareId</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Total</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, i) => (
              <TableRow
                key={i}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{i + 1}</TableCell>
                <TableCell>{item.promptCareId}</TableCell>
                <TableCell>
                  {item.name} {item.surname}
                </TableCell>
                <TableCell>{item.balance}</TableCell>
                <TableCell>
                  <a
                    href={`https://polygonscan.com/address/${item.publicKey}`}
                    target="_blank"
                  >
                    <div className="flex">
                      <img src={Matic} alt="matic" className="w-6 h-6 ml-2" />
                    </div>
                  </a>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default PatientBalance;
